import React, { useEffect, useState, useRef } from 'react';
import useOnScreen from '../../hooks/useOnScreen';

const easeOutQuad = t => t * ( 2 - t );
const frameDuration = 1000 / 60;

const currency = (number: number) : string => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return formatter.format(number)
}

const CountUpAnimation = ( { children, duration = 2000 } ) => {
	const countTo = parseInt( children, 10 );
	const [ count, setCount ] = useState( 0 );
  const [counterId, setCounterId] = useState<any>(undefined);
  const [animating, setAnimating] = useState(false);

  useEffect( () => {
    let frame = 0;
    const totalFrames = Math.round( duration / frameDuration );
    if (animating){
      clearInterval(counterId)
    }
    setAnimating(true)
    const counter = setInterval( () => {
      frame++;
      const progress = easeOutQuad( frame / totalFrames );
      setCount( countTo * progress );

      if ( frame === totalFrames ) {
        clearInterval( counter );
        setAnimating(false)
      }
    }, frameDuration );
    setCounterId(counter)
  }, [countTo] );

  if (!Number.isNaN(count)){
	  return currency(Math.floor( count ));
  }
  return "";
};

const CountUpAnimationWrapper = ( { children} ) => {
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  const [wasVisible, setWasVisible] = useState(isVisible);
  if (isVisible && !wasVisible){
    setWasVisible(true);
  } 
  if (isVisible || wasVisible){
    return (<span ref={ref}><CountUpAnimation>{children}</CountUpAnimation></span>);
  }
	return (<span ref={ref}></span>)
};

export default CountUpAnimationWrapper