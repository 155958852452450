import * as React from "react"
import { Link, HeadFC } from "gatsby"
import Layout from "../components/layout/layout"
import Access from "../components/access/access"
import SEO from "../components/seo/seo"

const AccessPage = ({location}) => {
  return (
    <Layout noNav={true} chat={false}>
        <Access location={location}/>
    </Layout>
  )
}

export default AccessPage

export const Head: HeadFC = () => <SEO title="Get Access"/>
