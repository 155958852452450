export const trackEvent = (eventLabel : string) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event' : eventLabel+"trigger"
    });
    if (window.LO && window.LO.events){
      window.LO.events.track(eventLabel)
    }
  }
}