import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"
import { navigate } from 'gatsby';
import axios from "axios"

import Button from "../button/button"
import Tag from "../tag/tag"
import CountUpAnimationWrapper from "../countup/countup"

import lightbulb from "../../images/lightbulb.svg"
import shield from "../../images/shield.svg"
import dollar from "../../images/dollar.svg"

import {API_URL} from "../../../config/env"

import "./access.scss"

import {getCookieValue} from "../../helpers/cookies"
import {validateEmail} from "../../helpers/email"
import {trackEvent} from "../../helpers/analytics"

type AccessProps = {
    location: any;
};
type AccessState = {
    budget: string;
    bedrooms: [];
    bathrooms: [];
    move_in: string;
    downpayment: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    living_situation: string,
    step: number;
    steps_count: number;
    submitted: boolean;
    submitting: boolean;
    scheduled_call: boolean;
};

const budgetOptions = ["$240-$320k", "$320-400k", "$400-600k", "$600k+"]
const downPaymentOptions = [
  ["$24-36k", "$36-48k", "$48k+"],
  ["$32-40k", "$48-60k", "$60k+"],
  ["$40-60k", "$60-90k", "$90k+"],
  ["$60-90k", "$90-120k", "$120k+"]
]

class Access extends React.Component<AccessProps, AccessState> {
    boundEventHandler: any;

    state: AccessState = {
      budget: "",
      bedrooms: [],
      bathrooms: [],
      move_in: "",
      downpayment: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      living_situation: "",
      step: 1,
      steps_count: 5,
      submitted: false,
      submitting: false,
      scheduled_call: false,
    };

    componentDidMount() : void {
      trackEvent("accessform"+this.state.step)
    }

    componentWillUnmount(): void {
      window.removeEventListener('message', this.boundEventHandler)
    }

    calendlyEventHandler(e : any){
      // if (e.data.event && (e.data.event =='calendly.event_scheduled')) {
      //   if (this.state.step==5){
      //     this.state.scheduled_call = true
      //     navigate("?scheduledcall=true")
      //   }
      // }
    }

    step1(){
      var budgetButtons = []
      for (var i = 0; i < budgetOptions.length; i++){
        const budgetOption = budgetOptions[i]
        budgetButtons.push(this.toggleButton(budgetOption, this.state.budget == budgetOption, () => {this.toggleBudget(budgetOption)})) 
      }
      var bedroomButtons = []
      var minBedrooms = 2
      var maxBedrooms = 5
      for (var i = minBedrooms; i <= maxBedrooms; i++){
        const index = i
        bedroomButtons.push(this.toggleButton(""+i, this.state.bedrooms.includes(i), () => {this.toggleBedroom(index)}))
      }
      var bathButtons = []
      var minBaths = 1
      var maxBaths = 3
      for (var i = minBaths; i <= maxBaths; i++){
        const index = i
        bathButtons.push(this.toggleButton(""+i, this.state.bathrooms.includes(i), () => {this.toggleBath(index)}))
      }
      return (
        <>
        <h2>{strings.early_access_question_1}</h2>
        <div className="step-1 form">
          <div className="field full-row budget">
            <div className="label">{strings.budget}</div>
            <div className="toggle-buttons">{budgetButtons}</div>
          </div>
          <div className="field bedrooms">
            <div className="label">{strings.bedrooms}<span>{strings.choose_all_that_apply}</span></div>
            <div className="toggle-buttons">{bedroomButtons}</div>
          </div>
          <div className="field bathrooms">
            <div className="label">{strings.bathrooms}</div>
            <div className="toggle-buttons">{bathButtons}</div>
          </div>
        </div>
        </>
      )
    }

    step2(){
      var options = [strings.move_in_when_1, strings.move_in_when_2, strings.move_in_when_3, strings.move_in_when_4, strings.move_in_when_5]
      var values = ["asap", "6", "12", "15", "flexible"]
      var optionButtons = []
      for (var i in options){
        const option = options[i]
        const value = values[i]
        optionButtons.push(this.toggleButton(option, this.state.move_in == value, () => {this.setState({move_in: value})}))
      }
      return (
        <>
        <h2>{strings.early_access_question_2}</h2>
        <div className="step-2 form">
          <div className="toggle-buttons">{optionButtons}</div>
          <div className="tip">
            <img src={lightbulb}/>
            <div className="text body1">{strings.move_in_tip}</div>
          </div>
        </div>
        </>
      )
    }

    step3(){
      var budgetOptionIndex = 0;
      var downpayments = []
      for (var i = 0; i < budgetOptions.length; i++){
        const budgetOption = budgetOptions[i]
        if (budgetOption == this.state.budget){
          budgetOptionIndex = i
          downpayments = downPaymentOptions[i]
          break
        }
      }
      var downpaymentButtons = []
      for (var i = 0; i < downpayments.length; i++){
        const downpaymentOption = downpayments[i]
        downpaymentButtons.push(this.toggleButton(downpaymentOption, this.state.downpayment == downpaymentOption, () => {this.toggleDownpayment(downpaymentOption)})) 
      }
      downpaymentButtons.push(this.toggleButton(strings.downpayment_assistance, this.state.downpayment == strings.downpayment_assistance, () => {this.toggleDownpayment(strings.downpayment_assistance)})) 
      return (
        <><h2>{strings.early_access_question_3}</h2>
        <div className="step-3 form">
          <div className="field full-row downpayment">
            {/* <div className="label">{strings.downpayment}</div> */}
            <div className="toggle-buttons">{downpaymentButtons}</div>
          </div>
          <div className="tip">
            <img src={lightbulb}/>
            <div className="body1">{strings.formatString(strings.downpayment_tip, downpayments[1], this.state.budget)}</div>
          </div>
        </div>
        </>
      )
    }

    step4(){
      return (
        <>
        <h2>{strings.early_access_question_4}</h2>
        <div className="step-4 form">
              <div className="field first_name">
                <input type="text" id="firstName" value={this.state.first_name} placeholder={strings.first_name} onChange={(event)=> {this.setState({first_name: event.target.value})}}/>
              </div>
              <div className="field last_name">
                <input type="text" id="lastName"  value={this.state.last_name} placeholder={strings.last_name} onChange={(event)=> {this.setState({last_name: event.target.value})}}/>
              </div>
              <div className="field email">
                <input type="email" id="email"  value={this.state.email} placeholder={strings.email} onChange={(event)=> {this.setState({email: event.target.value})}}/>
              </div>
              <div className="field phone">
                <input type="phone" id="phone"  value={this.state.phone} placeholder={strings.phone} onChange={(event)=> {this.setState({phone: this.formatPhoneNumber(event.target.value)})}}/>
              </div>
              <div className="field full-row living_situation">
                <div className="label">{strings.what_is_your_current_living_situation}</div>
                <select id="living_situation" onChange={(event)=> {this.setState({living_situation: event.target.value})}}>
                  <option>{strings.what_is_your_current_living_situation}</option>
                  <option value="rent">{strings.rent}</option>
                  <option value="own">{strings.own}</option>
                  <option value="other">{strings.other}</option>
                </select>
              </div>
            </div>
            <div className="body2 secure"><img src={shield}/>{strings.your_information_is_secure}</div>
        </>
      )
    }

    step5(){
      return (
        <>
        <div className="step-5">
          <h2>{strings.formatString(strings.access_step5_header, this.state.first_name)}</h2>
          <h4>{strings.access_step5_sub}</h4>
          <div className="calendly">
<iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3vjIG9inLb2hb8bW6TGS8UPswvg1EBDpW9Pt_r882bdPMtQjxxzR7KU0HWT2tg1s0cUdsz-nje?gv=true" style={{border: 0}} width="100%" height="600"></iframe>
          </div>
        </div>
        </>
      )
    }
  
    fullName() : string{
      return this.state.first_name + " " + this.state.last_name
    }

    back(){
      navigate(-1)
    }

    clear(){
      const invalids = document.querySelectorAll(".invalid");
      invalids.forEach((invalid) => {
        invalid.classList.remove("invalid")
      });
    }

    next(){
      this.clear()
      if (this.state.step < this.state.steps_count){
        if (this.state.step == 1){
          const budgetElement = document.querySelector(".budget");
          const bedroomsElement = document.querySelector(".bedrooms");
          const bathroomsElement = document.querySelector(".bathrooms");
          if (this.state.budget == ""){
            budgetElement?.classList.add("invalid")
          }
          if (this.state.bedrooms.length == 0){
            bedroomsElement?.classList.add("invalid")
          }
          if (this.state.bathrooms.length == 0){
            bathroomsElement?.classList.add("invalid")
          }
        }
        if(this.state.step == 2){
          if (this.state.move_in == ""){
            const moveInElement = document.querySelector(".step-2");
            moveInElement?.classList.add("invalid")
          }
        }
        if(this.state.step == 3){
          const downpaymentElement = document.querySelector(".step-3");
          if (this.state.downpayment == ""){
            downpaymentElement?.classList.add("invalid")
          }
        }
        if(this.state.step == 4){
          const firstnameElement = document.querySelector(".first_name");
          const lastnameElement = document.querySelector(".last_name");
          const emailElement = document.querySelector(".email");
          const phoneElement = document.querySelector(".phone");
          const livingSituationElement = document.querySelector(".living_situation");
          if (this.state.first_name == ""){
            firstnameElement?.classList.add("invalid")
          }
          if (this.state.last_name == ""){
            lastnameElement?.classList.add("invalid")
          }
          if (this.state.email == ""){
            emailElement?.classList.add("invalid")
          }
          if (!validateEmail(this.state.email)){
            emailElement?.classList.add("invalid")
          }
          if (this.state.phone == ""){
            phoneElement?.classList.add("invalid")
          }
          const phoneVal = this.strip_non_digits(this.state.phone)
          if (phoneVal.length < 10){
            phoneElement?.classList.add("invalid")
          }
          if (this.state.living_situation == ""){
            livingSituationElement?.classList.add("invalid")
          }
          const invalids = document.querySelectorAll(".invalid");
          if (invalids.length == 0){
            this.submit();
          }
          return;
        }
        const invalids = document.querySelectorAll(".invalid");
        if (invalids.length == 0){
          window.scrollTo(0,0)
          const nextStep = this.state.step+1
          navigate("?step="+ nextStep)
        }
      }
    }

    formatPhoneNumber(value) : string{
      // if input value is falsy eg if the user deletes the input, then just return
      if (!value) return value;
    
      // clean the input for any non-digit values.
      const phoneNumber = value.replace(/[^\d]/g, '');
    
      // phoneNumberLength is used to know when to apply our formatting for the phone number
      const phoneNumberLength = phoneNumber.length;
    
      // we need to return the value with no formatting if its less then four digits
      // this is to avoid weird behavior that occurs if you  format the area code to early
    
      if (phoneNumberLength < 4) return phoneNumber;
    
      // if phoneNumberLength is greater than 4 and less the 7 we start to return
      // the formatted number
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
    
      // finally, if the phoneNumberLength is greater then seven, we add the last
      // bit of formatting and return it.
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }

    async submit(){
      if (this.state.submitting || this.state.submitted){
        return;
      }
      this.setState({submitting: true})
      const apiURL = API_URL + "/access"
      try{
        await axios({
          method: 'post',
          url: apiURL,
          data: 
          {
            firstname: this.state.first_name,
            lastname: this.state.last_name,
            email: this.state.email,
            phone: this.state.phone,
            bedrooms: this.state.bedrooms.join(";"),
            bathrooms: this.state.bathrooms.join(";"),
            budget: this.state.budget,
            downpayment: this.state.downpayment,
            rent_or_own: this.state.living_situation,
            move_in: this.state.move_in,
            hubspot: getCookieValue("hubspotutk")
          }
        })
        this.setState({submitted: true})
        navigate("?step=5")
        window.scrollTo(0,0)
      }catch(err){
        this.setState({submitting: false, submitted: false})
        alert("Couldn't complete form because an unexpected error occurred")
      }
    }
    
    toggleBedroom(val){
      if (this.state.bedrooms.includes(val)){
        var newBedrooms = []
        for (var i in this.state.bedrooms){
          const existingVal = this.state.bedrooms[i]
          if (existingVal != val){
            newBedrooms.push(existingVal)
          }
        }
        this.setState({bedrooms: newBedrooms})
      }else{
        var newBedrooms = this.state.bedrooms
        newBedrooms.push(val)
        this.setState({bedrooms: newBedrooms})
      }
    }

    toggleBath(val){
      if (this.state.bathrooms.includes(val)){
        var newBathrooms = []
        for (var i in this.state.bathrooms){
          const existingVal = this.state.bathrooms[i]
          if (existingVal != val){
            newBathrooms.push(existingVal)
          }
        }
        this.setState({bathrooms: newBathrooms})
      }else{
        var newBathrooms = this.state.bathrooms
        newBathrooms.push(val)
        this.setState({bathrooms: newBathrooms})
      }
    }

    toggleBudget(val){
      this.setState({budget: val})
    }
    
    toggleDownpayment(val){
      this.setState({downpayment: val})
    }

    toggleButton(text, selected, onClick){
      var className = "toggle-button"
      if (selected){
        className += " selected"
      }
      var key = "togglebutton="+text
      return (
        <div key={key} className={className} onClick={onClick}>{text}</div>
      )
    }

    strip_non_digits(val : string) : string {
      return val.replace(/[^0-9.]/g,'')
    }

    componentDidUpdate(prevProps: Readonly<AccessProps>, prevState: Readonly<AccessState>, snapshot?: any): void {
      if (this.state.scheduled_call || (this.props.location.search.indexOf("?scheduledcall=true") == 0)){
        return;
      }
      var searchStep = 1;
      if (this.props.location.search != ""){
        if (this.props.location.search.indexOf("?step=") == 0){
          searchStep = parseInt(this.props.location.search.slice(6))
        }
      }
      if ((this.state.step != searchStep) && (searchStep >= 1) && (searchStep <= 5)){
        this.setState({step: searchStep})
        trackEvent("accessform"+searchStep)
      }
    }

    render() {
      var className = "questions-nav step-" + this.state.step
      var stepContent = <></>;
      var nextButton = <Button size="large next" onClick={this.next.bind(this)}>Next</Button>
      var tag_string = strings.early_access
      var chatButton = []
      switch(this.state.step){
        case 1:
          stepContent = this.step1();
          break;
        case 2:
          stepContent = this.step2();
          break;
        case 3:
          stepContent = this.step3();
          break;
        case 4:
          stepContent = this.step4();
          break;
        case 5:
          stepContent = this.step5();
          var downpayments = []
          for (var i = 0; i < budgetOptions.length; i++){
            const budgetOption = budgetOptions[i]
            if (budgetOption == this.state.budget){
              downpayments = downPaymentOptions[i]
              break
            }
          }
          const matches = downpayments[1].match(/(\d+)-(\d+)/)
          if (matches){
            tag_string = (<><span style={{marginRight: "0.5rem"}}>{strings.est_savings}</span><CountUpAnimationWrapper>{matches[1]}</CountUpAnimationWrapper>-<CountUpAnimationWrapper>{matches[2]}</CountUpAnimationWrapper>k</>)
          }
          nextButton = <div style={{width: "85px"}}></div>
          chatButton.push(<div key="chat"  className="chat-button" onClick={() => {LO.messenger.open()}}>Chat</div>)
          break;
      }
      
      return (
        <div id="access" className="access">
          <div className="container">
            <Tag type="yellow no-icon">{tag_string}</Tag>
            {stepContent}
          </div>
          <div className={className}>
            <div className="container">
            <Button size="large previous" nooutline={true} onClick={this.back.bind(this)}>{strings.previous}</Button>
            <div className="body1">
              <span className="question-label">{strings.early_access_question}</span>{strings.formatString(strings.early_access_question_state, this.state.step, this.state.steps_count)}
            </div>
            {nextButton}
            </div>
            <div className="progress"></div>
          </div>
          {chatButton}
        </div>
      )
    }
}

export default Access
